<template>
  <nav class="e-offcanvas__nav e-offcanvas__nav--account">
    <ul>
      <li>
        <router-link :to="{ name: 'index' }" v-html="backText" />
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BackButton',
  computed: {
    ...mapGetters(['gdprConfirmationNeeded']),

    backText() {
      let key = 'system.navigation.back'
      if (this.gdprConfirmationNeeded) {
        key += 'Short'
      }
      return this.$t(key)
    }
  }
}
</script>
