<template>
  <span v-html="message" />
</template>

<script>
import { subMilliseconds, formatDistanceStrict } from 'date-fns'
import deLocale from 'date-fns/locale/de'

export default {
  props: {
    idleTime: {
      type: Number,
      default: () => 2000
    },
    idleTimeBuffer: {
      type: Number,
      default: () => 1000
    },
    i18n: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    message: null,
    timerId: null,
    idleDate: null,
    idleBufferDate: null,
    counter: 0
  }),

  mounted() {
    this.startTimer()
  },

  destroyed() {
    window.clearTimeout(this.timerId)
  },

  methods: {
    t(key) {
      let values = []
      let len = arguments.length - 1
      while (len-- > 0) values[len] = arguments[len + 1]
      const i18n = this.i18n
      return i18n._t.apply(
        i18n,
        [key, i18n.locale, i18n._getMessages(), this].concat(values)
      )
    },
    startTimer() {
      this.idleDate = subMilliseconds(new Date(), this.idleTime)
      this.counter = this.idleTimeBuffer
      this.updateDates()
    },

    updateDates() {
      window.clearTimeout(this.timerId)
      this.idleBufferDate = subMilliseconds(new Date(), this.counter)
      this.message = this.t('chat.idleConfirmation.message', {
        idleTime: formatDistanceStrict(new Date(), this.idleDate, {
          locale: deLocale,
          addSuffix: true
        }),
        closeTime: formatDistanceStrict(this.idleBufferDate, new Date(), {
          locale: deLocale,
          addSuffix: true
        })
      })
      this.counter = this.counter - 1000
      if (this.counter > 0) {
        this.timerId = window.setTimeout(this.updateDates, 1000)
        return
      }
      this.$emit('countdown-ended')
    }
  }
}
</script>
